import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import React from 'react';
import { es } from 'date-fns/locale';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import format from "date-fns/format";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Input from "./Input";
import Button from "../../Buttons/Buttons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek, faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateRangeInput = ({ isDateSelected, setIsDateSelected, setDateAuxSelected, iniFecha, finFecha }) => {
  // date state
  const [range, setRange] = useState([
    {
      startDate: addDays(new Date(), -365),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  useEffect(() => {
    iniFecha(format(range[0].startDate, "dd/MM/yyyy"));
    finFecha(format(range[0].endDate, "dd/MM/yyyy"));
  }, [range]);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const CustomInputStart = React.forwardRef(({ value, onClick }, ref) => (
    <div className="custom-datepicker">
      <input
        ref={ref}
        value={isDateSelected ? value : null}
        onClick={onClick}
        className="form-control text-primary border-primary"
        placeholder="Desde"
        readOnly
      />
      <FontAwesomeIcon icon={faCalendar} onClick={onClick} />
    </div>
  ));

  const CustomInputEnd = React.forwardRef(({ value, onClick }, ref) => (
    <div className="custom-datepicker">
      <input
        ref={ref}
        value={isDateSelected ? value : null}
        onClick={onClick}
        className="form-control text-primary border-primary"
        placeholder="Hasta"
        readOnly
      />
      <FontAwesomeIcon icon={faCalendar} onClick={onClick} />
    </div>
  ));

  return (
    <>
      <div className="calendar-wrap">
        {/* <div className="calendar-button">
          <Button
            title={
              <FontAwesomeIcon
                icon={faCalendarWeek}
                style={{ fontSize: "1.25rem" }}
              />
            }
            override="override-btn-primary-icon"
            type="btn-rounded"
            color="btn-primary"
            onButtonClick={() => setOpen((open) => !open)}
          />
        </div> */}
        {/* <div className="calendar-from-text">
          <h5 className="text-primary">Desde: </h5>
        </div> */}
        <div className="calendar-from">
          <DatePicker
            selected={range[0].startDate}
            onChange={date => {
              setRange([{ ...range[0], startDate: date }]);
              setIsDateSelected(true);
              setDateAuxSelected(true);
            }}
            dateFormat="dd/MM/yyyy"
            customInput={<CustomInputStart />}
            showMonthDropdown
            showYearDropdown
            locale={es}
          />
        </div>
        {/* <div className="calendar-to-text">
          <h5 className="text-primary">Hasta: </h5>
        </div> */}
        <div className="calendar-to">
          <DatePicker
            selected={range[0].endDate}
            onChange={date => {
              setRange([{ ...range[0], endDate: date }]);
              setIsDateSelected(true);
              setDateAuxSelected(true);
            }}
            dateFormat="dd/MM/yyyy"
            customInput={<CustomInputEnd />}
            showMonthDropdown
            showYearDropdown
            locale={es}
          />
        </div>
      </div>
      {/* <div className="calendar-calendar" ref={refOne}>
        {open && (
          <DateRange
            onChange={(item) => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
          />
        )}
      </div> */}
    </>
  );
};

export default DateRangeInput;
