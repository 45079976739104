import React, { useState, useEffect, useRef } from "react";
import Button from "../../Buttons/Buttons";
import Modals from "../../Modals/Modals";
import Alert from "../../Alerts/Alert";
import { attendSubmissionData } from "../../../services/docSubmission";

import "../List.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faSpinner,
  faDownload,
  faArrowRight,
  faAddressCard,
  faCheck,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import {
  downloadDocument,
  responseDocument,
  responseAllDocuments,
} from "../../../services/docSubmission";

import FileDownload from "js-file-download";
import fileDownload from "js-file-download";

import { differenceInDays, parse, set } from "date-fns";

function CardSubmission({
  item,
  refreshList,
  showSuccess,
  setShowSuccess,
  selected,
  setSelected,
  setMainSelect,
  setAnexSelect,
  historico = false,
}) {
  const [principal, setPrincipal] = useState([]);
  const [anexos, setAnexos] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isCargoDownloading, setIsCargoDownloading] = useState(false);
  const [isAllDownloading, setIsAllDownloading] = useState(false);

  const subjectRef = useRef(null);
  const [isTextOverflow, setIsTextOverflow] = useState(false);
  const [isTrimmed, setIsTrimmed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showMainDownloadModal, setShowMainDownloadModal] = useState(false);
  const [showCargoDownloadModal, setshowCargoDownloadModal] = useState(false);
  const [isMainDownloading, setIsMainDownloading] = useState(false);

  //PARA MOSTRAR UN BOTON CUANDO SE SATURE EL ESPACIO DE TEXTO EN EL DIV
  useEffect(() => {
    const divElement = subjectRef.current;
    if (
      divElement &&
      divElement.scrollHeight > divElement.clientHeight &&
      !isTrimmed
    ) {
      // const trimmedText = subject.slice(0, -5);
      // setSubject(trimmedText + "...");
      setIsTextOverflow(true);
      setIsTrimmed(true);
    }
  }, [subjectRef]);

  //PARA EL LLENADO INICIAL DE LOS DOCUMENTOS EN CASO DE QUE ESTÉN EN LA SEGUNDA PÁGINA
  useEffect(() => {
    if (item.filesMetadata) {
      const auxPrincipal = [];
      const auxAnexos = [];
      for (const file of item.filesMetadata) {
        const fileFormatted = {
          name: file.fileName,
          size: file.bytesSize,
          sizeText: file.bytesSizeText,
          type: file.contentType,
          mainFile: file.mainFile,
          value: file.hashDocument,
        };
        if (file.mainFile) auxPrincipal.push(fileFormatted);
        else auxAnexos.push(fileFormatted);
      }
      setPrincipal(auxPrincipal);
      setAnexos(auxAnexos);
    }
  }, []);

  const handleModalAnexos = () => {
    setMainSelect(principal);
    setAnexSelect(anexos);
    setSelected({ ...selected, item: item, type: "modal-anexos" });
  };
  const handleModalSubject = () => {
    setMainSelect(principal);
    setAnexSelect(anexos);
    setSelected({ ...selected, item: item, type: "modal-subject" });
  };
  const handleModalContacto = () => {
    setMainSelect(principal);
    setAnexSelect(anexos);
    setSelected({ ...selected, item: item, type: "modal-contacto" });
  };
  const handleModalAtencion = () => {
    setMainSelect(principal);
    setAnexSelect(anexos);
    setSelected({ ...selected, item: item, type: "modal-atencion" });
  };
  const handleModalDetalle = () => {
    setMainSelect(principal);
    setAnexSelect(anexos);
    setSelected({ ...selected, item: item, type: "modal-detalle" });
  };

  const downloadMain = async (value, name) => {
    setShowMainDownloadModal(true);
    setIsMainDownloading(true);
    const data = await downloadDocument(value);
    if (!data.resultCode) {
      FileDownload(data, name);
    }
    setIsMainDownloading(false);
    setShowMainDownloadModal(false);
  };

  const downloadCargo = async () => {
    setshowCargoDownloadModal(true);
    setIsCargoDownloading(true);
    const data = await responseDocument(item.documentSubmissionNumber);
    if (!data[0].resultCode) {
      /* SE PROCEDE AL DESCARGO */
      fileDownload(data[0], data[1]);
    }
    setIsCargoDownloading(false);
    setshowCargoDownloadModal(false);
  };

  const abrirModalAtencion = async () => {
    setShowModal(true);
  };

  //Función para atender el expediente
  const atenderCargo = async (documentSubmissionNumber) => {
    const data = await attendSubmissionData(documentSubmissionNumber);
    if (data.resultCode === 0) {
      refreshList();
      setShowSuccess(true);
    } else {
      console.log(data.message);
    }
    setShowModal(false);
  };

  const downloadAll = async () => {
    // setShowDownloadModal(true); // Muestra el modal
    // setIsAllDownloading(true);
    // const data = await responseAllDocuments(item.documentSubmissionNumber);
    // if (!data[0].resultCode) {
    //   /* SE PROCEDE AL DESCARGO */
    //   fileDownload(data[0], data[1]);
    // }
    // setIsAllDownloading(false);
    // setShowDownloadModal(false); // Oculta el modal
  };

  return (
    <div className="card-submission">
      <div className="card-remitente">
        <h4 className="text-secondary">
          {`${item.documentTypeStr}`}{" "}
          {`${item.documentTypeStr !== "Sin RUC" ? item.documentNumber : ""}`}
        </h4>
        <h4 className="text-primary">{item.fullName}</h4>
        <div className="card-fechas text-dark">
          <FontAwesomeIcon icon={faClock} style={{ fontSize: "1.5rem" }} />
          <section>
            <h5>{`Fecha de registro: ${item.submissionDate} a las ${item.submissionHour}`}</h5>
            <h5>{`Días transcurridos${historico ? " hasta la atención: " : ":"} ${item.daysPassed} días`}</h5>
          </section>
        </div>
        {/* <h5 className="text-primary">{`Días hasta atención: ${item.fullName}`}</h5> */}
      </div>
      <div className="card-solicitud">
        {item.fileNumber !== "" && item.fileNumber !== null ? (
          <h4 className="text-secondary" style={{ maxWidth: "inherit" }}>
            {`N° de Expediente: ${item.fileNumber}`}
          </h4>
        ) : (
          <h4 className="text-secondary" style={{ maxWidth: "inherit" }}>
            Sin Número de Expediente
          </h4>
        )}
        {item.statementNumber !== "" && item.statementNumber !== null ? (
          <h4 className="text-secondary" style={{ maxWidth: "inherit" }}>
            {`Escrito: ${item.statementNumber}`}
          </h4>
        ) : (
          <h4 className="text-secondary" style={{ maxWidth: "inherit" }}>
            Sin Número de Escrito
          </h4>
        )}
        <h4 hidden>{`statementId: ${item.statementId}`}</h4>
        <h4 hidden>{`documentSubmissionNumber: ${item.documentSubmissionNumber}`}</h4>
        <div ref={subjectRef} className="card-subject">
          <h5 className="text-primary">{item.subject}</h5>
        </div>
        {isTextOverflow && isTrimmed && (
          <div className="button-modal">
            <Button
              type="btn-outline-white"
              override="override-btn-outline-icon"
              id={`doc-${principal[0].name}`}
              title={
                <div className="button-components text-secondary">
                  <p style={{ marginBottom: "4px" }}>
                    <u>Ver Asunto Completo</u>
                  </p>
                  <div style={{ width: "24px" }}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ fontSize: "1.25rem" }}
                    />
                  </div>
                </div>
              }
              onButtonClick={handleModalSubject}
            />
          </div>
        )}
      </div>
      <div className="card-documentos">
        <h4 className="text-primary">Documento principal:</h4>
        {principal.length > 0 && (
          <div className="button-export">
            <Button
              type="btn-outline-secondary"
              override="override-btn-outline-primary"
              title={
                <div className="button-components text-start">
                  <p style={{ marginLeft: "8px", marginTop: "5px", width: "200px" }}>
                    {principal[0].name.length > 30
                      ? principal[0].name.slice(0, 22) + "..."
                      : principal[0].name}
                  </p>
                  {/* <p style={{ marginBottom: "4px" }}>
                    <b>{`(${principal[0].sizeText})`}</b>
                  </p> */}
                  <div style={{ marginBottom: "17px", width: "24px" }}>
                    <FontAwesomeIcon
                      icon={isDownloading ? faSpinner : faDownload}
                      spinPulse={isDownloading}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </div>
                </div>
              }
              onButtonClick={() => {
                downloadMain(principal[0].value, principal[0].name);
              }}
            />
          </div>
        )}
        <Modals
          openModal={showMainDownloadModal}
          setOpenModal={setShowMainDownloadModal}
          title="Descarga de documentos"
          body={
            <div style={{ textAlign: "center" }}> {/* Asegura que el contenido esté centrado */}
              <div style={{ fontSize: "2rem", margin: "20px" }}> {/* Ajusta el tamaño y el margen del ícono */}
                {isMainDownloading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spinPulse={true}
                  />
                )}
              </div>
              <p style={{ color: '#042352', fontSize: '1.5rem' }}>Documento principal descargando. Espere un momento.</p> {/* Cambia el color y el tamaño del texto */}
            </div>
          }
          bd_static={true}
          closeButton={false}
        />
        {anexos.length > 0 && (
          <div className="button-modal">
            <Button
              type="btn-outline-white"
              override="override-btn-outline-icon-dark"
              id={`doc-${principal[0].name}`}
              title={
                <div className="button-components text-dark" style={{ position: "static" }}>
                  <p style={{ marginBottom: "4px" }}>
                    <u>Ver Documentos Anexos</u>
                  </p>
                  <div style={{ marginBottom: "0px", width: "30px" }}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ marginTop: "9px", fontSize: "0.9rem" }}
                    />
                  </div>
                </div>
              }
              onButtonClick={handleModalAnexos}
            />
          </div>
        )}
      </div>
      <div className="card-acciones">
        {!historico && (
          <Button
            type="btn-warning"
            override="override-btn-outline-primary"
            title={
              <div className="button-components text-white">
                <h4 style={{ marginBottom: "4px" }}>Atender</h4>
                <div style={{ marginBottom: "13px", width: "23px" }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ fontSize: "0.9rem" }}
                  />
                </div>
              </div>
            }
            onButtonClick={abrirModalAtencion}
          />
        )}
        <Button
          type="btn-secondary"
          override="override-btn-outline-primary"
          title={
            <div className="button-components text-white">
              <h4 style={{ marginBottom: "1px" }}>
                Ver Detalle
              </h4>
              <div style={{ marginBottom: "13px", width: "23px" }}>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ fontSize: "0.9rem" }}
                />
              </div>
            </div>
          }
          onButtonClick={() => {
            if (!historico) handleModalAtencion();
            else handleModalDetalle();
          }}
        />
        <Button
          type="btn-secondary"
          override="override-btn-outline-primary"
          title={
            <div className="button-components text-white">
              <h4 style={{ marginBottom: "4px" }}>Datos de Contacto</h4>
              <div style={{ marginBottom: "17px", width: "24px" }}>
                <FontAwesomeIcon
                  icon={faAddressCard}
                  style={{ fontSize: "0.7rem" }}
                />
              </div>
            </div>
          }
          onButtonClick={handleModalContacto}
        />
        <Button
          type="btn-secondary"
          override="override-btn-outline-primary"
          title={
            <div className="button-components text-white">
              <h4 style={{ marginBottom: "4px" }}>Descargar Cargo</h4>
              <div style={{ marginBottom: "18px", width: "24px" }}>
                <FontAwesomeIcon
                  icon={isCargoDownloading ? faSpinner : faDownload}
                  spinPulse={isCargoDownloading}
                  style={{ fontSize: "0.8rem" }}
                />
              </div>
            </div>
          }
          onButtonClick={downloadCargo}
        />
        <Modals
          openModal={showCargoDownloadModal}
          setOpenModal={setshowCargoDownloadModal}
          title="Descarga de documentos"
          body={
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: "2rem", margin: "20px" }}>
                <FontAwesomeIcon
                  icon={isCargoDownloading ? faSpinner : faCheck}
                  spinPulse={isCargoDownloading}
                />
              </div>
              <p style={{ color: '#042352', fontSize: '10rem' }}>Documento cargo descargando. Espere un momento.</p>
            </div>
          }
          bd_static={true}
          closeButton={false}
        />
        {/* <Button
          type="btn-secondary"
          override="override-btn-outline-primary"
          title={
            <div className="button-components text-white">
              <h4 style={{ marginBottom: "4px" }}>Descargar Todo</h4>
              <div style={{ marginBottom: "18px", width: "24px" }}>
                <FontAwesomeIcon
                  icon={isAllDownloading ? faSpinner : faDownload}
                  spinPulse={isAllDownloading}
                  style={{ fontSize: "0.8rem" }}
                />
              </div>
            </div>
          }
          onButtonClick={downloadAll}
        /> */}
        <Modals
          openModal={showDownloadModal}
          setOpenModal={setShowDownloadModal}
          title="Descarga de documentos"
          body={
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: "2rem", margin: "20px" }}>
                <FontAwesomeIcon
                  icon={isAllDownloading ? faSpinner : faCheck}
                  spinPulse={isAllDownloading}
                />
              </div>
              <p style={{ color: '#042352', fontSize: '10rem' }}>Documentos descargando. Espere un momento.</p>
            </div>
          }
          bd_static={true}
          closeButton={false}
        />
      </div>
      <Modals
        openModal={showModal}
        setOpenModal={setShowModal}
        title="Atención de expediente"
        body="¿Desea confirmar la atención de este expediente?"
        footer={["Confirmar", "Cancelar"]}
        handleConfirm={() => atenderCargo(item.documentSubmissionNumber)}
        handleCancel={() => setShowModal(false)}
      />
    </div>
  );
}

export default CardSubmission;
