import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  deleteDocument,
  downloadDocument,
} from "../../../services/docSubmission";

import Modal from "../../Modals/Modals";
import Button from "../../Buttons/Buttons";

import "../List.scss";
import fileDownload from "js-file-download";

function FileItem(props) {
  const {
    index,
    name,
    size,
    value,
    openModal,
    setOpenModal,
    onClick,
    isDeleted,
    isButton = false,
  } = props;

  const [selected, isSelected] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const handleModal = () => {
    isSelected(true);
    setOpenModal(true);
  };

  //Funcion para elimianr los archivos asociados
  const deleteFile = async () => {
    let tempLabel;
    const data = await deleteDocument(value);
    if (data.resultCode === 0) {
      // Caso positivo
      isDeleted(value);
    } else if (data.resultCode === 400) {
      tempLabel = "En estos momentos se encuentran problemas con el servidor.";
    } else {
      tempLabel =
        "Algunos de los documentos no se lograron subir porque no obedecen a las indicaciones.";
    }
    isSelected(false);
    setOpenModal(false);
  };

  const handleClick = async (value, name) => {
    setIsDownloading(true);
    const data = await downloadDocument(value);
    if (!data.resultCode) {
      /* SE PROCEDE AL DESCARGO */
      fileDownload(data, name);
    }
    setIsDownloading(false);
  };

  return (
    <div className="file-item" onClick={() => handleClick(value, name)}>
      <div className="file-name">
        <p className="text-secondary" style={{ marginBottom: "0px" }}>
          {name}
        </p>
        {size !== null && (
          <p className="text-secondary" style={{ marginBottom: "0px" }}>
            {` (${size})`}
          </p>
        )}
      </div>
      {!isButton && !isDownloading && (
        <div className="bin-icon text-secondary">
          <Button
            type="btn-outline-white"
            override="override-btn-outline-icon"
            id={`trash-${name}`}
            title={
              <FontAwesomeIcon
                icon={faTrash}
                style={{ fontSize: "1rem", color: "#004EA8" }}
              />
            }
            onButtonClick={handleModal}
          />
        </div>
      )}
      {isDownloading && (
        <div className="bin-icon text-secondary">
          <FontAwesomeIcon
            icon={faSpinner}
            spinPulse={isDownloading}
            style={{ fontSize: "1.25rem" }}
          />
        </div>
      )}

      {/*------------MANEJO DE MODALES PARA LA SOBRECARGA DE BOTONES------------*/}
      {selected && (
        <Modal
          openModal={openModal}
          setOpenModal={setOpenModal}
          title="Eliminado de Documentos"
          size="md"
          body={`¿Desea eliminar el archivo ${name}?`}
          footer={["Confirmar", "Cerrar"]}
          handleConfirm={() => deleteFile()}
        />
      )}
    </div>
  );
}

export default FileItem;
