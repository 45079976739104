import React, { useState, useEffect } from "react";

import List from "../../../components/Lists/List";
import Modal from "../../../components/Modals/Modals";
import Button from "../../../components/Buttons/Buttons";
import Spinner from "../../../components/Loaders/Spinner.js";
import Modals from "../../../components/Modals/Modals";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faDownload,
  faEnvelope,
  faMobile,
  faPhone,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import "../BandejaSolicitudes.scss";
import ModalRegistro from "./ModalRegistro";

import {
  downloadDocument,
  saveSubmissionData,
} from "../../../services/docSubmission";
import fileDownload from "js-file-download";

async function saveSubmission(registro) {
  let body = {
    documentSubmissionNumber: registro.documentSubmissionNumber,
    documentType: registro.documentType,
    documentNumber: registro.documentNumber,
    fileNumber: registro.fileNumber,
    subject: registro.subject,
    observations: registro.observations,
    partType: registro.partType,
  };

  const data = await saveSubmissionData(body);
  return data;
}

function Bandeja({ solicitudes, isHistorico, isLoading, refreshList, showSuccess, setShowSuccess }) {
  const [mainSelect, setMainSelect] = useState([]);
  const [anexSelect, setAnexSelect] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState({
    item: null,
    type: null,
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloading, setDownloading] = useState({});
  const [registro, setRegistro] = useState({
    documentSubmissionNumber: "",
    documentType: "",
    documentTypeStr: "",
    documentNumber: "",
    fullName: "",
    submissionDate: "",
    submissionHour: "",
    daysPassed: "",
    hasFile: "",
    fileNumber: "",
    subject: "",
    email: "",
    cellPhone: "",
    phoneNumber: "",
    phoneExtension: "",
    responseDate: "",
    responseHour: "",
    statementNumber: "",
    secretaryName: "",
    isPartRelated: "",
    partType: "",
    observations: "",
  });

  //Estados para la descarga de los anexos
  const [showAnexDownloadModal, setShowAnexDownloadModal] = useState(false);
  const [isAnexDownloading, setIsAnexDownloading] = useState(false);

  useEffect(() => {
    if (!openModal)
      setSelected({
        item: null,
        type: null,
      });
  }, [openModal]);
  useEffect(() => {
    if (selected.item !== null) setOpenModal(true);
  }, [selected]);

  const handleClick = async (value, name) => {
    setDownloading({ ...downloading, [value]: true });
    setShowAnexDownloadModal(true); // Muestra el modal
    setIsAnexDownloading(true); // Indica que el documento anexo está descargando
    const data = await downloadDocument(value);
    if (!data.resultCode) {
      /* SE PROCEDE AL DESCARGO */
      fileDownload(data, name);
    }
    setIsAnexDownloading(false); // Indica que el documento anexo ha terminado de descargar
    setDownloading({ ...downloading, [value]: false });
    setShowAnexDownloadModal(false); // Oculta el modal después de que la descarga haya terminado
  };

  const handleSubmitEscrito = async () => {
    saveSubmission(registro).then((data) => {
      if (data.resultCode === 0) {
        /* SE PROCEDE A REALIZAR LOS PASOS CONSECUENTES A LA DESCARGA */
        setOpenModal(false);
      } else {
        console.warn("Salio mal");
      }
    });
  };

  const modalCases = () => {
    if (selected.item) {
      switch (selected.type) {
        case "modal-anexos":
          return (
            <>
              {/* Wrap adjacent elements in a React fragment */}
              <Modals
                openModal={showAnexDownloadModal}
                setOpenModal={setShowAnexDownloadModal}
                title="Descarga de documentos"
                body={
                  <div style={{ textAlign: "center" }}>
                    <div style={{ fontSize: "2rem", margin: "20px" }}>
                      {isAnexDownloading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spinPulse={true}
                        />
                      )}
                    </div>
                    <p style={{ color: '#042352', fontSize: '1.5rem' }}>Documento anexo descargando. Espere un momento.</p>
                  </div>
                }
                bd_static={true}
                closeButton={false}
              />
              <Modal
                openModal={openModal}
                setOpenModal={setOpenModal}
                title="Documentos Anexos"
                size="md"
                body={
                  <div className="button-modal-anexos">
                    {anexSelect.map((item, index) => (
                      <Button
                        key={index}
                        type="btn-outline-secondary"
                        override="override-btn-outline-primary"
                        title={
                          <div className="button-components">
                            <p style={{ marginBottom: "14px" }}>{item.name}</p>
                            <div style={{ marginBottom: "18px", width: "24px" }}>
                              <FontAwesomeIcon
                                icon={downloading[item.value] ? faSpinner : faDownload}
                                spinPulse={downloading[item.value]}
                                style={{ fontSize: "1rem" }}
                              />
                            </div>
                          </div>
                        }
                        onButtonClick={() => {
                          setDownloading({ ...downloading, [item.value]: true });
                          handleClick(item.value, item.name);
                        }}
                      />
                    ))}
                  </div>
                }
                closeButton={true}
              />
            </>
          );

        case "modal-subject":
          return (
            <Modal
              openModal={openModal}
              setOpenModal={setOpenModal}
              title="Asunto"
              size="md"
              body={selected.item.subject}
              closeButton={true}
            />
          );
        case "modal-contacto":
          return (
            <Modal
              openModal={openModal}
              setOpenModal={setOpenModal}
              title="Datos de Contacto"
              size="md"
              body={
                <div className="modal-contacto text-secondary">
                  <section className="flex-lane">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      style={{ fontSize: "1.5rem" }}
                    />
                    <h5>{selected.item.email}</h5>
                  </section>
                  <section className="flex-lane">
                    <FontAwesomeIcon
                      icon={faMobile}
                      style={{ fontSize: "1.5rem", paddingLeft: "0.25rem" }}
                    />
                    <h5>{`${selected.item.cellPhone !== null &&
                      selected.item.cellPhone !== ""
                      ? selected.item.cellPhone
                      : "-"
                      }`}</h5>
                  </section>
                  <section className="flex-lane">
                    <FontAwesomeIcon
                      icon={faPhone}
                      style={{ fontSize: "1.5rem" }}
                    />
                    <h5>{`${selected.item.phoneNumber !== null &&
                      selected.item.phoneNumber !== ""
                      ? selected.item.phoneNumber
                      : "-"
                      } ${selected.item.phoneExtension !== null &&
                        selected.item.phoneExtension !== ""
                        ? `(Anexo - ${selected.item.phoneExtension})`
                        : ""
                      }`}</h5>
                  </section>
                </div>
              }
              closeButton={true}
            />
          );
        case "modal-atencion":
          return (
            <Modal
              openModal={openModal}
              setOpenModal={setOpenModal}
              title="Registro de Nuevo Escrito"
              body={
                <ModalRegistro
                  item={selected.item}
                  historico={isHistorico}
                  principal={mainSelect}
                  anexos={anexSelect}
                  disabled={disabled}
                  setDisabled={setDisabled}
                  registro={registro}
                  setRegistro={setRegistro}
                />
              }
              dialogClassName="modal-90w"
              footer={["Registrar Escrito", "Cerrar"]}
              handleConfirm={() => handleSubmitEscrito()}
              confirmDisabled={disabled}
              closeButton={true}
            />
          );
        case "modal-detalle":
          return (
            <Modal
              openModal={openModal}
              setOpenModal={setOpenModal}
              title="Detalle del Escrito"
              body={
                <ModalRegistro
                  item={selected.item}
                  historico={isHistorico}
                  principal={mainSelect}
                  anexos={anexSelect}
                  disabled={!disabled}
                  setDisabled={setDisabled}
                  registro={registro}
                  setRegistro={setRegistro}
                />
              }
              dialogClassName="modal-90w"
              confirmDisabled={!disabled}
              closeButton={true}
            />
          );

        default:
          return;
      }
    }
  };

  return (
    <div className="table-bandeja">
      <div className="table-headers">
        <h4 className="text-primary">Remitente</h4>
        <h4 className="text-primary">Solicitud</h4>
        <h4 className="text-primary">Documentos</h4>
        <h4 className="text-primary">Acciones</h4>
      </div>
      {isLoading === false ? (
        <div className="table-content">
          {solicitudes.length > 0 ? (
            <List
              listItem="card-submissions"
              list={solicitudes !== null ? solicitudes : []}
              refreshList={refreshList}
              showSuccess={showSuccess}
              setShowSuccess={setShowSuccess}
              selected={selected}
              setSelected={setSelected}
              setMainSelect={setMainSelect}
              setAnexSelect={setAnexSelect}
              historico={isHistorico}
            />
          ) : (
            <div className="no-content">
              <h4 className="text-dark">No se encontraron resultados.</h4>
            </div>
          )}
        </div>
      ) : (
        <Spinner></Spinner>
      )}
      <div className="table-pages"></div>

      {/*------------MANEJO DE MODALES PARA LA SOBRECARGA DE BOTONES------------*/}
      {modalCases()}
    </div>
  );
}
export default Bandeja;
