import axios from "axios";

export async function autoLogin(userKey) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/security/autologin`,
      {
        keyCode: process.env.REACT_APP_KEY_CODE,
        data: { value: userKey },
      }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function autoLoginStatement(userKey) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/security/autologinStatement`,
      {
        keyCode: process.env.REACT_APP_KEY_CODE,
        data: { value: userKey },
      }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

