import React from "react";

import "./Loaders.scss";

const Spinner = ({ margin = "2rem" }) => {
  return (
    <div
      className="loader-spinner"
      style={{ marginTop: margin, marginBottom: margin }}
    >
      <div
        className="spinner-border text-primary"
        style={{ width: "4rem", height: "4rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
