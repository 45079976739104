import React, { useState, useEffect } from "react";

import Button from "../../components/Buttons/Buttons.js";
import Pagination from "../../components/Buttons/Pagination.js";
import ButtonGroup from "../../components/Buttons/ButtonGroup.js";
import Title from "../../components/Title/Title.js";
import Alert from "../../components/Alerts/Alert.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faFileExcel,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import "./BandejaSolicitudes.scss";

import Filters from "./Components/Filters";
import Bandeja from "./Components/Bandeja.js";

import {
  searchSubmission,
  exportSearchSubmissions,
} from "../../services/docSubmission";
import fileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";

async function searchBody(filters, historico, page) {
  let body = {
    firstResult: page,
    maxResults: 5,
    submissionState: historico ? 3 : 2,
    userId: 888,
    documentType: !filters.tipo_doc
      ? ""
      : (filters.tipo_doc === "dni" && 1) ||
      (filters.tipo_doc === "ruc" && 2) ||
      (filters.tipo_doc === "sin_ruc" && 3),
    documentNumber: !filters.tipo_doc ? "" : filters.numero,
    fullName: filters.nombre,
    fileNumber: filters.num_exp,
    email: filters.correo,
    fromDate: filters.iniFecha,
    toDate: filters.finFecha,
  };

  const data = await searchSubmission(body);
  return data;
}
async function exportBody(filters, historico) {
  let body = {
    submissionState: historico ? 3 : 2,
    userId: 888,
    documentType: !filters.tipo_doc
      ? ""
      : (filters.tipo_doc === "dni" && 1) ||
      (filters.tipo_doc === "ruc" && 2) ||
      (filters.tipo_doc === "sin_ruc" && 3),
    documentNumber: !filters.tipo_doc ? "" : filters.numero,
    fullName: filters.nombre,
    fileNumber: filters.num_exp,
    email: filters.correo,
    fromDate: filters.iniFecha,
    toDate: filters.finFecha,
  };

  const data = await exportSearchSubmissions(body);
  return data;
}

function BandejaSolicitudes() {
  //Constantes de la función
  const menuButtons = [
    {
      id: "nuevos",
      name: "btn-nuevos",
      title: "Nuevos",
      color: "nada",
      onButtonClick: () => {
        setIsHistorico(false);
        setPageInicial(0);
      },
      buttonPressed: true,
    },
    {
      id: "historico",
      name: "btn-historico",
      title: "Histórico",
      color: "nada",
      onButtonClick: () => {
        setIsHistorico(true);
        setPageInicial(0);
      },
    },
  ];

  //Hooks
  // We start with an empty list of items.
  const [showFilters, setShowFilters] = useState(false);
  const [isHistorico, setIsHistorico] = useState(false);
  const [pageInicial, setPageInicial] = useState(0);
  const [submissions, setSubmissions] = useState({
    maxItems: 0,
    isLoading: false,
    data: [],
  });

  const [refreshKey, setRefreshKey] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);

  const [searchFilters, setSearchFilters] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [curPage, setCurPage] = useState(0);
  const [filters, setFilters] = useState({
    tipo_doc: "",
    numero: "",
    nombre: "",
    num_exp: "",
    correo: "",
    iniFecha: "",
    finFecha: "",
  });

  useEffect(() => {
    setSubmissions({
      ...submissions,
      maxItems: 0,
      isLoading: true,
      data: [],
    });
    searchBody(filters, isHistorico, 1).then((data) => {
      if (data.resultCode === 0) {
        setSubmissions({
          ...submissions,
          maxItems: data.total,
          isLoading: false,
          data: data.list,
        });
      } else if (data.resultCode === 400) {
        setSubmissions({
          ...submissions,
          maxItems: 0,
          isLoading: false,
          data: [],
        });
      } else {
        setSubmissions({
          ...submissions,
          maxItems: 0,
          isLoading: false,
          data: [],
        });
      }
    });
    return () => {
      setSearchFilters(false);
    };
  }, [isHistorico, searchFilters]);

  useEffect(() => {
    setSubmissions({
      ...submissions,
      maxItems: 0,
      isLoading: true,
      data: [],
    });
    searchBody(filters, isHistorico, curPage + 1).then((data) => {
      if (data.resultCode === 0) {
        setSubmissions({
          ...submissions,
          maxItems: data.total,
          isLoading: false,
          data: data.list,
        });
      } else if (data.resultCode === 400) {
        setSubmissions({
          ...submissions,
          maxItems: 0,
          isLoading: false,
          data: [],
        });
      } else {
        setSubmissions({
          ...submissions,
          maxItems: 0,
          isLoading: false,
          data: [],
        });
      }
    });
    return () => {
      setSearchFilters(false);
    };
  }, [refreshKey]);

  const handlePageClick = () => {
    setPageInicial(pageInicial + 1);
    setSubmissions({
      ...submissions,
      maxItems: 0,
      isLoading: true,
      data: [],
    });
    searchBody(filters, isHistorico, curPage + 1).then((data) => {
      if (data.resultCode === 0) {
        setSubmissions({
          ...submissions,
          maxItems: data.total,
          isLoading: false,
          data: data.list,
        });
      }
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("user")) {
      navigate("/");
    }
  }, []);

  const handleExcelExport = () => {
    setIsDownloading(true);
    exportBody(filters, isHistorico).then((data) => {
      if (!data[0].resultCode) {
        /* SE PROCEDE AL DESCARGO */
        fileDownload(data[0], data[1]);
      }
    });
    setIsDownloading(false);
  };

  return (
    <>
      {localStorage.getItem("user") && (
        <main className="bandeja_solicitudes-wrapper">
          <Title
            title="Bandeja de Entrada"
            expand={true}
            textColor="text-primary"
          />
          <section className="bandeja_solicitudes-content">
            <section className="bandeja-menu">
              {showSuccess &&
                <Alert
                  type="alert-success"
                  label="¡La solicitud ha sido atendida con éxito!"
                  show={showSuccess}
                  setShow={setShowSuccess}
                />
              }
              <div className="menu-botoneria">
                <div className="multiple-boton">
                  <ButtonGroup
                    override="override-btn-group"
                    items={menuButtons}
                  />
                </div>
                <div className="filtros-busqueda">
                  <Button
                    type="btn-outline-secondary"
                    override="override-btn-primary-outline-icon"
                    title={
                      <div
                        className="button-components"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "0.5rem",
                        }}
                      >
                        <h4 style={{ marginBottom: "4px" }}>
                          Filtros de Búsqueda
                        </h4>
                        <div style={{ width: "20px" }}>
                          <FontAwesomeIcon
                            icon={showFilters ? faArrowUp : faArrowDown}
                            style={{ fontSize: "1.5rem" }}
                          />
                        </div>
                      </div>
                    }
                    buttonPressed={showFilters}
                    onButtonClick={() => {
                      setShowFilters(!showFilters);
                    }}
                  />
                </div>
              </div>
              {showFilters && (
                <Filters
                  filters={filters}
                  setFilters={setFilters}
                  setSearchFilters={setSearchFilters}
                />
              )}
            </section>
            <menu className="bandeja-resultados">
              <div className="resultados-header">
                <h3 className="text-primary"> Resultados de Búsqueda:</h3>
                <div className="button-export">
                  <Button
                    type="btn-secondary"
                    override="override-btn-primary-outline-icon"
                    title={
                      <div
                        className="button-components"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          gap: "0.5rem",
                        }}
                      >
                        <h4 style={{ marginBottom: "4px" }}>
                          Exportar a Excel
                        </h4>
                        <div style={{ width: "20px" }}>
                          <FontAwesomeIcon
                            icon={isDownloading ? faSpinner : faFileExcel}
                            spinPulse={isDownloading}
                            style={{ fontSize: "1.25rem" }}
                          />
                        </div>
                      </div>
                    }
                    onButtonClick={() => {
                      handleExcelExport();
                    }}
                  />
                </div>
              </div>
              <hr style={{ margin: "0.5rem 0 0.5rem 0" }} />
              <div className="resultados-paginacion">
                <Pagination
                  maxItems={submissions.maxItems}
                  itemsPerPage={5}
                  items={submissions.data}
                  onPageClick={handlePageClick}
                  setCurPage={setCurPage}
                  pageInicial={pageInicial}
                  setPageInicial={setPageInicial}
                >
                  <Bandeja
                    solicitudes={submissions.data}
                    isHistorico={isHistorico}
                    isLoading={submissions.isLoading}
                    refreshList={() => setRefreshKey(refreshKey + 1)}
                    showSuccess={showSuccess}
                    setShowSuccess={setShowSuccess}
                  />
                </Pagination>
              </div>
            </menu>
          </section>
        </main>
      )}
    </>
  );
}

export default BandejaSolicitudes;
