import React, { useState, useEffect } from "react";
import "../Form.scss";

function RadioButtons(props) {
  /*
    id -> IDENTIFICADOR UNICO PARA EL FORM
    options -> Arreglo diseñado para múltples componentes como opciones 
    {
      id -> un id por cada opcion
      name -> Nombre correspondiente al botón para su asociación con el label
      label -> se refiere al nombre del label que se manejará -> es de forma compleja para estilizar el checkbox
      disabled -> Por defecto en falso | maneja estados de activación
    }
    checked -> Si uno delos valores de estos estará marcado como default ==> "Es el name"
    helper -> definido como un apoyo debajo de la casilla del input  
    onChange -> para evaluar el cambio cosntante por eventos
    required -> Para futuras validaciones, si es un campo obligatorio
    errors -> TRUE OR FALSE -> En caso se active un caso de error se cambia a rojo -> otro estado
    feedback -> feedback en caso haya un error
    ...other para otras propiedades que se puedan ir adquiriendo*/
  const {
    id,
    options,
    checked,
    helper,
    errors = false,
    feedback,
    onChange,
    inline = false,
    ...checkProps
  } = props;

  const [focused, setFocused] = useState(false);
  const [isChecked, setIsChecked] = useState(checked);

  const handleFocus = (e) => {
    setFocused(true);
  };

  const handleClick = (name) => {
    setIsChecked(name);
  };

  useEffect(() => {
    if (isChecked) onChange(isChecked);
  }, [isChecked]);

  return (
    <>
      <div className="radio-group">
        {options.map((option, index) => (
          <div
            className={`form-check ${inline && "form-check-inline"}`}
            key={index}
          >
            <div className="checkbox-text-checkbox">
              <input
                className={`form-check-input border-primary checkbox`}
                type="radio"
                id={option.id}
                name={option.name}
                checked={isChecked === option.name}
                onClick={() => handleClick(option.name)}
                onBlur={handleFocus}
                focused={!errors ? focused.toString() : "true"}
                {...checkProps}
              />
            </div>
            <div className="checkbox-text-text">
              <label className="form-check-label" htmlFor={option.name}>
                {option.label}
              </label>
            </div>
          </div>
        ))}
      </div>
      <div className="helper-text">
        {helper && !(feedback && errors) && (
          <div
            id={`helper_${id}`}
            className={`form-text ${feedback && "valid-div"}`}
          >
            <p className="text-dark">{helper}</p>
          </div>
        )}
        {feedback && errors && (
          <div id={`helperror_${id}`} className="form-text">
            <p className="text-danger">{feedback}</p>
          </div>
        )}
        {!(helper && feedback && errors) && (
          <div
            id={`none_${id}`}
            className={`margin-bottom ${feedback && "valid-div"}`}
          ></div>
        )}
      </div>
    </>
  );
}

export default RadioButtons;
