import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import BandejaSolicitudes from "./pages/BandejaSolicitudes/BandejaSolicitudes.js";
import Authentication from "./pages/BandejaSolicitudes/Authentication.js";
import AuthenticationStatement from "./pages/BandejaSolicitudes/AuthenticationStatement.js";

import NewTabEscrito from "./pages/BandejaSolicitudes/NewTabEscrito.js";

import NavBar from "./components/NavBar/NavBar.js";
import Home from "./pages/Home/Home.js";

import "./main.scss";

function App() {
  const location = useLocation();
  const isEscritoRoute = location.pathname.startsWith(`${process.env.REACT_APP_CONTEXT}/informacion-escrito`);

  useEffect(() => {
    if (isEscritoRoute) {
      document.body.classList.add("body-bg-white");
    }
  }, [isEscritoRoute]);

  return (
    <div>
      {!isEscritoRoute && (
        <div className="wrapper" id="main">
          <div className="app-navbar">
            <NavBar />
          </div>
          <div className="app-component bg-white">
            <Routes>
              <Route
                path={`${process.env.REACT_APP_CONTEXT}`}
                exact
                element={<BandejaSolicitudes />}
              />
              {/* Redireccionamiento por parámetros de URL para manejo de login */}
              <Route
                path={`${process.env.REACT_APP_CONTEXT}/login`}
                exact
                element={<Authentication />}
              />
            </Routes>
          </div>
        </div>
      )}
      {isEscritoRoute && (
        <Routes>
          <Route
            path={`${process.env.REACT_APP_CONTEXT}/informacion-escrito`}
            exact
            element={<NewTabEscrito />}
          />
          <Route
            path={`${process.env.REACT_APP_CONTEXT}/informacion-escrito/login`}
            exact
            element={<AuthenticationStatement />}
          />
        </Routes>
      )}
    </div>
  );
}

export default App;
