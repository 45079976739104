import React, { useState } from "react";
import "./Buttons.scss";

function ButtonGroup(props) {
  /*SUMIT ES PARA EL USO DE FORMULARIOS 
    Items => Clase de estilo...
    {
      id -> id del button
      name -> nombre del button
      Title -> se refiere al titulo
      route -> for the anchor route
      Color -> el color del botón -> btn-primary o btn-secondary
      buttonDisabled -> si corresponde a un disabled o no
      onButtonClick -> para el manejo de funciones
    }
    type -> tipo del botón -(Desrito en Buttons.js)
    buttonPressed -> si corresponde a un estado de presionado (sin hover) o no
    {
      For the use of modals
      - modal -> true or false si es que pertenece o no a un botón que activará a un modal
      - target -> area-labelled para el modal que se va a adherir al Modal correspondiente
    }
    overrides=> Corresponde a algunas otras clases que se quieren reemplazar del Bootstrap
     */
  const {
    items,
    type = "btn-outline-primary",
    buttonDisabled,
    modal = false,
    target,
    override,
  } = props;

  const [isChecked, setIsChecked] = useState(null);

  const handleChange = (name) => {
    setIsChecked(name);
  };

  return (
    <div
      className={`btn-group ${override}`}
      role="group"
      aria-label="Button Group"
    >
      {items.map((item, index) => (
        <>
          <input
            type="radio"
            className={`btn-check`}
            name={item.name}
            id={item.id}
            autoComplete="off"
            onChange={() => handleChange(item.name)}
            onClick={item.onButtonClick}
            checked={
              isChecked === null
                ? item.buttonPressed
                : isChecked === item.name
                ? true
                : false
            }
          />
          <label
            id={`label-${item.id}`}
            className={`btn ${type} ${item.color} ${override}`}
            htmlFor={item.id}
          >
            {item.title}
          </label>
        </>
      ))}
    </div>
  );
}

export default ButtonGroup;
