import React, { useState, useEffect } from "react";
import Input from "./Input.js";

import List from "../../Lists/List";

function AutocompleteInput(props) {
  const {
    handleEffect,
    handleSelect,
    value,
    suggestions,
    setSuggestions,
    renderList,
    ...inputProps
  } = props;

  const [isAutoFilled, setAutoFilled] = useState(value !== null);

  useEffect(() => {
    if (!isAutoFilled) handleEffect();
    return setAutoFilled(false);
  }, [value]);

  const handleSuggestionClick = (suggestion) => {
    handleSelect(suggestion);
    setSuggestions([]);
    setAutoFilled(true);
  };

  return (
    <div className="autocomplete">
      <div className="auto-input">
        <Input 
        value={value} 
        overide="input-basic"
        {...inputProps} 
        />
      </div>
      {suggestions.length > 0 && (
        <div className="list list-options">
          <ul className="list-group">
            {suggestions.map((suggestion, index) => (
              <li
                className="list-group-item override-list-options"
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {renderList(suggestion)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AutocompleteInput;
