import React, {useState, useEffect} from "react";

import FileItem from "./CustomItems/FileItem";
import CardSubmission from "./CustomItems/CardSubmission";

import "./List.scss";

function List({ type = "list-group", listItem, list, refreshList, showSuccess, setShowSuccess, ...options }) {
  /*
    type = representa el tipo de lista que se manejará
    listItem = Representa al tipo de los CustomItems que se manejarán{
      file-item: FileItem.js
    }
    list = Representa la lista como tal como un sub-componente o clases.
    ...options -> Other props for the listItem that will be passed
  */

  const itemType = (listItem, item) => {
    switch (listItem) {
      case "file-item":
        return (
          <FileItem
            name={item.name}
            value={item.value}
            size={item.sizeText}
            {...options}
          />
        );
      case "card-submissions":
        return <CardSubmission item={item} refreshList={refreshList} showSuccess={showSuccess} setShowSuccess={setShowSuccess} {...options} />;
      default:
        return;
    }
  };

  return (
    <ul className={type}>
      <div className="list">
        {list.map((item, index) => (
          <li className={`list-group-item override-${listItem}`} key={index}>
            {itemType(listItem, item)}
          </li>
        ))}
      </div>
    </ul>
  );
}
export default List;
