import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { autoLoginStatement } from "../../services/authentication.js";

function AuthenticationStatement() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let loginIndex = location.search.toString().indexOf("auth=");
        let userKey = "";
        if (loginIndex !== -1) {
            userKey = location.search
                .toString()
                .substring(loginIndex + "auth=".length);
        } else {
            if (!localStorage.getItem("user")) {
                navigate("/");
            }
        }
        if (userKey.length >= 0) {
            autoLoginStatement(userKey).then((data) => {
                if (data.resultCode === 0) {
                    localStorage.setItem("user", data.userId);
                    localStorage.setItem("documentSubmissionNumber", data.documentSubmissionNumber);
                    navigate(`${process.env.REACT_APP_CONTEXT}/informacion-escrito`);
                } else {
                    localStorage.removeItem("user");
                    navigate("/");
                }
            });
        }
    }, []);

    return <div>{ }</div>;
}
export default AuthenticationStatement;
