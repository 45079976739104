import { useState } from "react";
import axios from "axios";

import useFetch from "../hooks/useFetch";

//Luego eliminar--------------------------------

export function requestCode(email) {
  let data = null;
  let error = null;

  const url = `${process.env.REACT_APP_API_URL}/docSubmission/requestCode`;
  const attr = {
    keyCode: process.env.REACT_APP_KEY_CODE,
    email: email,
  };
  axios
    .post(url, attr)
    .then((response) => {
      data = response.data;
    })
    .catch((err) => {
      error = err.response;
    });

  return { data, error };
}

export async function validateCode(code, email) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/validateCode`,
      {
        keyCode: process.env.REACT_APP_KEY_CODE,
        validationCode: code,
        email: email,
      }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function senderData(body) {
  //Algunas validaciones iniciales
  switch (body.documentType) {
    case "dni":
      body.documentType = 1;
      break;
    case "ruc":
      body.documentType = 2;
      break;
    case "sin_ruc":
      body.documentType = 3;
      break;
    default:
      break;
  }
  if (body.cellPhone.length <= 4) body.cellPhone = "";
  if (body.phoneNumber.length <= 4) body.phoneNumber = "";
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/saveSenderData`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: body }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}
export async function documentData(body) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/saveDocumentData`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: body }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function uploadDocument(file, docNumber, main = 0) {
  var formData = new FormData();
  formData.append("file", file);
  formData.append("keyCode", process.env.REACT_APP_KEY_CODE);
  formData.append("documentSubmissionNumber", docNumber);
  formData.append("mainFile", main);
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/uploadDocument`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function downloadDocument(value) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/downloadDocument`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: { value: value } },
      {
        responseType: "blob",
      }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function deleteDocument(value) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/deleteDocument`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: { value: value } }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function responseDocument(value) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/responseDocument`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: { value: value } },
      {
        responseType: "blob",
      }
    );
    const customHeader = request.headers["sigea_filename"];
    return [request.data, customHeader];
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function responseAllDocuments(value) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/downloadZip`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: { value: value } },
      {
        responseType: "blob",
      }
    );
    const customHeader = request.headers["sigea_filename"];
    return [request.data, customHeader];
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function attendSubmissionData(value) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/changeSubmissionState`,
       { keyCode: process.env.REACT_APP_KEY_CODE, 
        userId: localStorage.getItem("user"),
        documentSubmissionNumber: value }       
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function findByDocumentNumber(value) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/findByDocumentNumber`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: { value: value } }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function searchSubmission(filters) {
  filters.keyCode = process.env.REACT_APP_KEY_CODE;
  filters.userId = localStorage.getItem("user");
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/searchSubmissions`,
      filters
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function exportSearchSubmissions(filters) {
  filters.keyCode = process.env.REACT_APP_KEY_CODE;
  filters.userId = localStorage.getItem("user");
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/exportSearchSubmissions`,
      filters,
      {
        responseType: "blob",
      }
    );
    const customHeader = request.headers["sigea_filename"];
    return [request.data, customHeader];
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function getSubmissionData(body) {
  body.keyCode = process.env.REACT_APP_KEY_CODE;
  body.userId = localStorage.getItem("user");
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/getSubmissionData`,
      body
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function saveSubmissionData(body) {
  body.keyCode = process.env.REACT_APP_KEY_CODE;
  body.userId = localStorage.getItem("user");
  console.log(body);
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/saveSubmissionData`,
      body
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}

export async function acFileNumberSecretary(value) {
  try {
    const request = await axios.post(
      `${process.env.REACT_APP_API_URL}/docSubmission/acFileNumberSecretary`,
      { keyCode: process.env.REACT_APP_KEY_CODE, data: { value: value } }
    );
    return request.data;
  } catch (exception) {
    return {
      resultCode:
        exception.response !== null ? exception.response.status : null,
      message: exception.response !== null ? exception.response.data : null,
    };
  }
}
