import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./Buttons.scss";

function PaginatedItems({
  itemsPerPage,
  items,
  maxItems,
  onPageClick,
  setCurPage,
  children,
  pageInicial,
  setPageInicial,
}) {
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    setPageCount(Math.ceil(maxItems / itemsPerPage));
  }, [itemsPerPage, items, maxItems]);

  useEffect(() => {
    onPageClick();
  }, [itemOffset]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setCurPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % maxItems;
    setItemOffset(newOffset);
  };

  return (
    <>
      {children}
      {maxItems > itemsPerPage && (
        <ReactPaginate
          previousLabel="<"
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          forcePage={pageInicial == 0 ? 0 : (itemOffset / itemsPerPage)}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeLinkClassName="page-active"
          renderOnZeroPageCount={null}
        />
      )}
    </>
  );
}

function Pagination(props) {
  const { items, maxItems, itemsPerPage, onPageClick, setCurPage, children, pageInicial, setPageInicial } =
    props;

  return (
    <PaginatedItems
      items={items}
      maxItems={maxItems}
      itemsPerPage={itemsPerPage}
      onPageClick={onPageClick}
      setCurPage={setCurPage}
      pageInicial={pageInicial}
      setPageInicial={setPageInicial}
    >
      {children}
    </PaginatedItems>
  );
}

export default Pagination;
