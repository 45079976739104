import React, { useState, useEffect } from "react";

import FormContainer from "../../../components/Form/FormContainer";
import Button from "../../../components/Buttons/Buttons";
import Input from "../../../components/Form/Controls/Input";
import AutocompleteInput from "../../../components/Form/Controls/AutocompleteInput";
import TextArea from "../../../components/Form/Controls/TextArea";
import RadioButtons from "../../../components/Form/Controls/RadioButtons";
import List from "../../../components/Lists/List";
import Alert from "../../../components/Alerts/Alert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import {
  acFileNumberSecretary,
  getSubmissionData,
} from "../../../services/docSubmission";
import Spinner from "../../../components/Loaders/Spinner";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { format, parse, differenceInDays } from "date-fns";

async function getBody(item) {
  let body = {
    documentSubmissionNumber: item.documentSubmissionNumber,
  };
  const data = await getSubmissionData(body);
  return data;
}

function ModalRegistro({
  item,
  historico,
  principal,
  anexos,
  disabled,
  setDisabled,
  registro,
  setRegistro,
}) {
  const radioOptions = [
    {
      id: "option_1",
      name: 1,
      label: "Demandante",
    },
    {
      id: "option_2",
      name: 2,
      label: "Demandado",
    },
    {
      id: "option_3",
      name: 4,
      label: "Árbitro",
    },
    {
      id: "option_4",
      name: 5,
      label: "Abogado/Procurador/Representante Legal",
    },
    {
      id: "option_5",
      name: 3,
      label: "Otros",
    },
  ];

  const [listSecretary, setListSecretary] = useState([]);
  useEffect(() => {
    getBody(item).then((data) => {
      if (data.resultCode === 0) {
        setRegistro({
          documentSubmissionNumber: data.data.documentSubmissionNumber,
          documentType: data.data.documentType,
          documentTypeStr: data.data.documentTypeStr,
          documentNumber: data.data.documentNumber,
          fullName: data.data.fullName,
          submissionDate: data.data.submissionDate,
          submissionHour: data.data.submissionHour,
          daysPassed: data.data.daysPassed,
          hasFile: data.data.hasFile,
          fileNumber: data.data.fileNumber,
          subject: data.data.subject,
          email: data.data.email,
          cellPhone: data.data.cellPhone,
          phoneNumber: data.data.phoneNumber,
          phoneExtension: data.data.phoneExtension,
          responseDate: !data.data.responseDate
            ? format(new Date(), "dd/MM/yyyy")
            : data.data.responseDate,
          responseHour: !data.data.responseHour
            ? format(new Date(), "dd/MM/yyyy")
            : data.data.responseHour,
          statementNumber: data.data.statementNumber,
          secretaryName: data.data.secretaryName,
          isPartRelated: data.data.isPartRelated,
          partType: data.data.partType,
          observations: !data.data.observations ? "" : data.data.observations,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (
      registro.hasFile === "Sí" &&
      registro.partType !== "" &&
      registro.partType
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [registro.hasFile, registro.partType]);
  //PARA EL USO DE AUTOCOMPLETE INPUT

  const handleEffect = () => {
    if (registro.fileNumber)
      if (registro.fileNumber.length >= 4) {
        acFileNumberSecretary(registro.fileNumber).then((data) => {
          if (data.resultCode === 0) {
            setListSecretary(data.list);
          }
        });
      } else setListSecretary([]);
    else setListSecretary([]);
  };

  const handleSelect = (suggestion) => {
    setRegistro({
      ...registro,
      secretaryName: suggestion.secretary,
      fileNumber: suggestion.fileNumber,
      hasFile: "Sí",
    });
  };
  const returnSecretary = (suggestion) => {
    return (
      <p>
        {suggestion.fileNumber} {""} {suggestion.secretary}
      </p>
    );
  };

  const handleChange = (checked) => {
    setRegistro({ ...registro, partType: checked });
  };

  return (
    <menu className="modal-registro">
      <FormContainer title="Datos del Expediente">
        {registro.documentSubmissionNumber ? (
          <section className="datos-expediente">
            <div className="expediente">
              <div className="text-exp text-dark">
                <h5>N° de Expediente</h5>
              </div>
              <div className="input-exp">
                <AutocompleteInput
                  id="nom"
                  name="nombre"
                  type="text"
                  value={registro.fileNumber}
                  onChange={(e) => {
                    setRegistro({ ...registro, fileNumber: e.target.value });
                  }}
                  suggestions={listSecretary}
                  setSuggestions={setListSecretary}
                  handleEffect={handleEffect}
                  handleSelect={handleSelect}
                  readOnly={registro.hasFile !== "No" || !registro.hasFile}
                  renderList={returnSecretary}
                />
              </div>
              {(registro.hasFile !== "No" || !registro.hasFile) &&
                !historico && (
                  <div className="modification-exp">
                    <Button
                      onButtonClick={(e) => {
                        setRegistro({
                          ...registro,
                          secretaryName: "",
                          fileNumber: "",
                          hasFile: "No",
                        });
                      }}
                      override="override-btn-link-modify"
                      title={<p className="text-secondary">Modificar</p>}
                      type="btn-link"
                    />
                  </div>
                )}
              <div className="escrito">
                <div className="text-esc text-dark">
                  <h5>Escrito</h5>
                </div>
                <div className="input-esc">
                  <Input
                    id="esc"
                    name="escrito"
                    type="text"
                    value={registro.statementNumber}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="arbitro">
              <div className="text-arb text-dark">
                <h5>Secretario Arbitral</h5>
              </div>
              <div className="input-arb">
                <Input
                  id="arb"
                  name="arbitro"
                  type="text"
                  value={registro.secretaryName}
                  readOnly={true}
                />
              </div>
            </div>
          </section>
        ) : (
          <Spinner margin="0rem"></Spinner>
        )}
      </FormContainer>
      <FormContainer title="Asunto">
        <section className={`datos-asunto`}>
          {registro.documentSubmissionNumber ? (
            <h5 className="text-primary">{registro.subject}</h5>
          ) : (
            <Spinner margin="0rem"></Spinner>
          )}
        </section>
      </FormContainer>
      <FormContainer title="Documentos">
        <section className="datos-documentos">
          {registro.documentSubmissionNumber ? (
            <>
              <div className="doc-principal">
                <label className="form-label text-primary">
                  <h4>{`Documento Principal:`}</h4>
                </label>
                <List
                  listItem="file-item"
                  override="override-btn-outline-primary"
                  list={principal}
                  onClick={() => console.log("Luego se implementará")}
                  isButton={true}
                />
              </div>
              {anexos.length > 0 && (
                <div className="doc-anexos">
                  <label className="form-label text-primary">
                    <h4>{`Documento(s) Anexo(s):`}</h4>
                  </label>
                  <List
                    listItem="file-item"
                    list={anexos}
                    onClick={() => console.log("Luego se implementará")}
                    isButton={true}
                  />
                </div>
              )}
            </>
          ) : (
            <div style={{ width: "100%" }}>
              <Spinner></Spinner>
            </div>
          )}
        </section>
      </FormContainer>
      <FormContainer title="Remitente">
        <section className="datos-remitente">
          {registro.documentSubmissionNumber ? (
            <>
              <h4 className="text-secondary">
                {`${registro.documentTypeStr}`}{" "}
                {`${registro.documentTypeStr !== "Sin RUC"
                  ? registro.documentNumber
                  : ""
                  }`}
              </h4>
              <h4 className="text-primary">{registro.fullName}</h4>
              <div className="card-fechas text-dark">
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ fontSize: "1.5rem" }}
                />
                <section>
                  <h5>{`Fecha de ${historico ? "atención" : "inscripción"}: ${registro.submissionDate
                    } a las ${registro.submissionHour}`}</h5>
                  <h5>{`Días transcurridos${historico ? " hasta la atención: " : ":"
                    } ${-differenceInDays(
                      parse(registro.submissionDate, "dd/MM/yyyy", new Date()),
                      parse(registro.responseDate, "dd/MM/yyyy", new Date())
                    )} días`}</h5>
                </section>
              </div>
            </>
          ) : (
            <div style={{ width: "100%" }}>
              <Spinner margin="1.5rem"></Spinner>
            </div>
          )}
        </section>
        {registro.documentSubmissionNumber && (
          <section className="relacion-expediente">
            {!registro.isPartRelated && !historico && (
              <div className="aviso-parte">
                <Alert
                  label="El remitente no se encuentra relacionado al expediente"
                  type="alert-danger"
                  close={false}
                  show={true}
                ></Alert>
                <h5 className="text-danger">
                  Indique la relación que tendría la parte:
                </h5>
              </div>
            )}
            <RadioButtons
              id="relacion-expediente"
              options={radioOptions}
              inline={true}
              checked={registro.partType}
              onChange={handleChange}
              readOnly={historico}
              disabled={historico}
            />
          </section>
        )}
      </FormContainer>
      <FormContainer title="Observaciones">
        <section className="datos-observaciones">
          <TextArea
            onChange={(e) => {
              setRegistro({ ...registro, observations: e.target.value });
            }}
            value={registro.observations}
            id="asunto"
            name="asunto"
            placeholder="En caso sea requerido, ingrese las observaciones."
            height="114px"
            readOnly={historico}
          />
        </section>
      </FormContainer>
    </menu>
  );
}
export default ModalRegistro;
