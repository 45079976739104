import React from "react";
import "./Title.scss";

import Button from "../Buttons/Buttons";
//Barra de navegación que estará presente en todas las pantallas
//position-absolute top-0 start-50 translate-middle
function Title(props) {
  /* Title -> se refiere al titulo
     Subtitle -> se refiere al subtitulo
     Expand -> Si se expandirá la barra naranja del titulo
     Back -> Si se manejará un botón que regresará a la pantalla anterior
     TextColor -> Color del texto referente [text-white || text-primary]*/
  const { title, subtitle, expand, back, route, textColor } = props;
  return (
    <header className="main-title" id="main-title">
      <h1 className={`${textColor}`}>{title}</h1>
      {!expand ? (
        <hr className="hr-title"></hr>
      ) : (
        <hr className="hr-title hr-title-expand"></hr>
      )}
      {!back ? (
        <>
          {subtitle && (
            <div className="wrap-subtitle">
              {subtitle && <h2 className={`${textColor}`}>{subtitle}</h2>}
            </div>
          )}
        </>
      ) : (
        <>
          {subtitle && (
            <div className="wrap-subtitle">
              {subtitle && <h2 className={`${textColor}`}>{subtitle}</h2>}
              <Button
                title="Regresar"
                type="btn-rounded"
                anchor
                route={route}
                color="btn-secondary"
              />
            </div>
          )}
        </>
      )}
    </header>
  );
}

export default Title;
