import React, { useState, useEffect } from "react";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import ReactSelect, { components } from 'react-select';
import { format, isDate } from 'date-fns';

import Select from "../../../components/Form/Controls/Select.js";
import Button from "../../../components/Buttons/Buttons.js";
import Input from "../../../components/Form/Controls/Input.js";
import DateRangeInput from "../../../components/Form/Controls/DateRange";

import { filterInputs } from "../../../constants/Forms/Filters_Bandeja";

import { findByDocumentNumber } from "../../../services/docSubmission";

import { faBroom, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addDays, set } from "date-fns";

function Filters({ filters, setFilters, setSearchFilters }) {
  const values = [
    { index: 0, value: "", label: "Tipo de Documento" },
    { index: 1, value: "dni", label: "DNI" },
    { index: 2, value: "ruc", label: "RUC" },
    { index: 3, value: "sin_ruc", label: "Sin RUC" },
  ];

  const [readOnly, isReadOnly] = useState(
    filters.numero.length > 0 || filters.tipo_doc === "sin_ruc"
  );
  const [nombreApi, setNombreApi] = useState("");
  const [iniFecha, setIniFecha] = useState("");
  const [finFecha, setFinFecha] = useState("");
  const [expedientes, setExpedientes] = useState([]); //Para el expediente

  const [searchOptions, setSearchOptions] = useState([]);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [dateAuxSelected, setDateAuxSelected] = useState(false);

  useUpdateEffect(() => {
    setFilters({
      ...filters,
      iniFecha: iniFecha,
      finFecha: finFecha,
    });
  }, [iniFecha, finFecha, dateAuxSelected]);

  useUpdateEffect(() => {
    setFilters({ ...filters, numero: "", nombre: "" });
    if (filters.tipo_doc === "") {
      isReadOnly(false);
    } else if (filters.tipo_doc === "sin_ruc") isReadOnly(false);
    else if (nombreApi.length > 0) isReadOnly(false);
    else isReadOnly(true);
  }, [filters.tipo_doc]);

  // useUpdateEffect(() => {
  //   if (
  //     (filters.tipo_doc === "dni" && filters.numero.toString().length === 8) ||
  //     (filters.tipo_doc === "ruc" && filters.numero.toString().length === 11)
  //   ) {
  //     findByDocumentNumber(filters.numero).then((data) => {
  //       if (data.resultCode === 0) {
  //         isReadOnly(true);
  //         setNombreApi(data.data.value);
  //         setFilters({ ...filters, nombre: data.data.value });
  //       } else {
  //         isReadOnly(false);
  //         setNombreApi("");
  //         setFilters({ ...filters, nombre: "" });
  //       }
  //     });
  //   } else isReadOnly(false);
  // }, [filters.numero]);

  const cleanFilters = () => {
    document.getElementById("doc").selectedIndex = 0;
    setFilters({
      tipo_doc: "",
      numero: "",
      nombre: "",
      num_exp: "",
      correo: "",
      iniFecha: "",
      finFecha: "",
    });
    setIsDateSelected(false);
    setSearchFilters(true);
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        El expediente no existe
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className="menu-filters">
      <div className="filter-tipo-documento">
        <Select
          onChange={(e) => {
            setFilters({ ...filters, tipo_doc: e.target.value });
          }}
          options={values}
          selection={filters.tipo_doc}
          {...filterInputs[0]}
        />
      </div>
      {filters.tipo_doc !== "" && (
        <>
          {filters.tipo_doc !== "sin_ruc" && (
            <div className="filter-numero">
              <Input
                value={filters.numero}
                placeholder={
                  (filters.tipo_doc === "dni" && "N° de DNI") ||
                  (filters.tipo_doc === "ruc" && "N° de RUC")
                }
                onChange={(e) => {
                  setFilters({ ...filters, numero: e.target.value });
                }}
                {...filterInputs[1]}
              />
            </div>
          )}
          <div
            className={`${filters.tipo_doc === "sin_ruc"
              ? "filter-nombre-sin-ruc"
              : "filter-nombre"
              }`}
          >
            <Input
              value={filters.nombre}
              placeholder={
                (filters.tipo_doc === "dni" && "Nombres y Apellidos") ||
                (filters.tipo_doc === "ruc" && "Razón Social") ||
                (filters.tipo_doc === "sin_ruc" && "Nombre del Consorcio")
              }
              onChange={(e) => {
                setFilters({ ...filters, nombre: e.target.value });
              }}
              {...filterInputs[2]}
            />
          </div>
        </>
      )}
      <div className="filter-num-exp">
        <ReactSelect
          styles={{
            control: (provided) => ({
              ...provided,
              backgroundColor: 'white',
              fontSize: '16px',
              color: 'black',
              border: '1px solid #71829A',
              borderRadius: '0.375rem',
              boxShadow: 'none',
              '&:hover': {
                border: '1px solid #71829A',
              },
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused ? '#004EA8' : 'white',
              color: state.isFocused ? 'white' : 'black',
              '&:hover': {
                backgroundColor: '#004EA8',
              },
            }),
          }}
          placeholder="N° de Expediente"
          components={{
            NoOptionsMessage
          }}
          value={filters.num_exp ? searchOptions.find(option => option.value === filters.num_exp) : null}
          onChange={(selectedOption) => {
            setFilters({ ...filters, num_exp: selectedOption.value });
          }}
          onInputChange={(inputValue) => {
            if (inputValue && inputValue.length >= 3) {
              fetch(`${process.env.REACT_APP_API_URL}/docSubmission/autocompleteFileNumber`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  keyCode: "50ede0dfe9ad8440ef94e524ce354dc07a0644fa55522f9f5c069d82e2b57218",
                  data: {
                    value: inputValue
                  }
                }),
              })
                .then(response => response.json())
                .then(data => {
                  const newOptions = data.list.map(expediente => ({
                    value: expediente.value,
                    label: expediente.value
                  }));
                  setSearchOptions(newOptions);
                });
            } else {
              setSearchOptions([]);
            }
          }}
          options={searchOptions}
        />
      </div>
      <div className="filter-correo">
        <Input
          value={filters.correo}
          onChange={(e) => {
            setFilters({ ...filters, correo: e.target.value });
          }}
          suggestions={expedientes}
          {...filterInputs[4]}
        />
      </div>
      <div className="filter-fecha">
        <DateRangeInput isDateSelected={isDateSelected} setIsDateSelected={setIsDateSelected} setDateAuxSelected={setDateAuxSelected} iniFecha={setIniFecha} finFecha={setFinFecha} />
      </div>
      <div className="filter-botones">
        <Button
          type="btn-round"
          color="btn-secondary"
          override="override-btn-primary-outline-icon"
          title={
            <div
              className="button-components"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <h4 style={{ marginBottom: "4px" }}>Buscar</h4>
              <FontAwesomeIcon icon={faSearch} style={{ fontSize: "1.5rem" }} />
            </div>
          }
          onButtonClick={() => {
            setSearchFilters(true);
          }}
        />
        <Button
          type="btn-outline-secondary"
          override="override-btn-primary-outline-icon"
          title={
            <div
              className="button-components"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <h4 style={{ marginBottom: "4px" }}>Limpiar</h4>
              <FontAwesomeIcon icon={faBroom} style={{ fontSize: "1.5rem" }} />
            </div>
          }
          onButtonClick={() => {
            cleanFilters();
          }}
        />
      </div>
    </div>
  );
}

export default Filters;
