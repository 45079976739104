import React, { useState, useEffect } from "react";

import "./Alert.scss";
import Button from "../Buttons/Buttons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleCheck,
  faCircleExclamation,
  faCircleMinus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

/* Componente reutilizable con nombre genérico
  -> ALERTS para el manejo de avisos en el sistema */
const alertIcon = (type) => {
  switch (type) {
    case "alert-info":
      return (
        <FontAwesomeIcon icon={faCircleInfo} style={{ fontSize: "1.625rem" }} />
      ); //Svg-icon

    case "alert-success":
      return (
        <FontAwesomeIcon
          icon={faCircleCheck}
          style={{ fontSize: "1.625rem" }}
        />
      ); //Svg-icon

    case "alert-danger":
      return (
        <FontAwesomeIcon
          icon={faCircleMinus}
          style={{ fontSize: "1.625rem" }}
        />
      ); //Svg-icon

    case "alert-warning":
      return (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          style={{ fontSize: "1.625rem" }}
        />
      ); //Svg-icon

    case "alert-loading":
      return (
        <FontAwesomeIcon
          icon={faSpinner}
          style={{ fontSize: "1.625rem" }}
          spinPulse
        />
      ); //Svg-icon

    default:
      return;
  }
};

function Alert(props) {
  /* 
    type -> el tipo del input -> 
    {
      alert-success -> De color verde e indica cuando se realizó adecuadamente una acción 
      alert-danger -> De color rojo e indica cuando se realizó incorrectamente una acción
      alert-warning -> De color amarrillo para indicar un "warning" en el sistema
      alert-info -> De color azul secundario para manejar información adicional
    }
    label -> se refiere al texto "label" que se manejará en la alerta
    close -> Indica si se podrá cerrar o no el alert
    show, setShow -> ESTADOS USADOS PARA PODER CERRAR LA ALERTA
  */

  const { type, label, close = true, show, setShow } = props;

  if (show) {
    return (
      <div
        className={`alert ${
          type === "alert-loading" ? "alert-info" : type
        } alert-dismissible d-flex hide show`}
        role="alert"
      >
        <div className={`alert-padding`} />
        <div className="alert-content">
          <div className="alert-icon">{alertIcon(type)}</div>
          <div className="alert-label">{<h5>{label}</h5>}</div>
          <div className="alert-button">
            {close && (
              <Button
                type="btn-close"
                onButtonClick={() => {
                  setShow(false);
                }}
              ></Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Alert;
