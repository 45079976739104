import React, { useState, useEffect } from "react";

import List from "../../components/Lists/List";
import {
    getSubmissionData,
} from "../../services/docSubmission";

import Spinner from "../../components/Loaders/Spinner";

async function getBody() {
    let body = {
        documentSubmissionNumber: localStorage.documentSubmissionNumber,
    };
    const data = await getSubmissionData(body);
    return data;
}

function NewTabEscrito() {
    const [principal, setPrincipal] = useState([]);
    const [anexos, setAnexos] = useState([]);
    const [registro, setRegistro] = useState({
        documentSubmissionNumber: "",
        statementNumber: "",
        submissionDate: "",
        submissionHour: "",
        partType: "",
        fullName: "",
        subject: "",
    });
    const opciones = [
        [1, "Demandante"],
        [2, "Demandado"],
        [3, "Otros"],
        [4, "Árbitro"],
        [5, "Abogado/Procurador/Representante Legal"]
    ];

    const getPartTypeLabel = (partType) => {
        const option = opciones.find(option => option[0] === partType);
        return option ? option[1] : "Desconocido";
      };

    useEffect(() => {
        getBody().then((data) => {
            if (data.resultCode === 0) {
                setRegistro({
                    documentSubmissionNumber: data.data.documentSubmissionNumber,
                    fileNumber: data.data.fileNumber,
                    statementNumber: data.data.statementNumber,
                    submissionDate: data.data.submissionDate,
                    submissionHour: data.data.submissionHour,
                    partType: data.data.partType,
                    fullName: data.data.fullName,
                    subject: data.data.subject,
                });
                const auxPrincipal = [];
                const auxAnexos = [];
                for (const file of data.data.filesMetadata) {
                    const fileFormatted = {
                        name: file.fileName,
                        size: file.bytesSize,
                        sizeText: file.bytesSizeText,
                        type: file.contentType,
                        mainFile: file.mainFile,
                        value: file.hashDocument,
                    };
                    if (file.mainFile) auxPrincipal.push(fileFormatted);
                    else auxAnexos.push(fileFormatted);
                }
                setPrincipal(auxPrincipal);
                setAnexos(auxAnexos);
            }
        });
    }, []);

    return (
        <menu className="newtab-escrito">
            {registro.documentSubmissionNumber ? (
                <section className="datos-escrito" style={{ marginBottom: 10 }}>
                    <h3 className="text-primary"><b>INFORMACIÓN DEL ESCRITO</b></h3>
                    <div className="escrito text-row">
                        <div className="text-esc text-dark">
                            <h5>Nro. de Expediente: </h5>
                        </div>
                        <h5>{registro.fileNumber}</h5>
                    </div>
                    <div className="escrito text-row">
                        <div className="text-esc text-dark">
                            <h5>Nro. Escrito: </h5>
                        </div>
                        <h5>{registro.statementNumber}</h5>
                        <div className="text-esc text-dark">
                            <h5>Fecha de registro: </h5>
                        </div>
                        <h5>{registro.submissionDate} {registro.submissionHour}</h5>
                    </div>
                    <div className="sender text-row">
                        <div className="text-esc text-dark">
                            <h5>Presentado por:</h5>
                        </div>
                        <div className="">
                            <h5>{getPartTypeLabel(registro.partType)} - {registro.fullName}</h5>
                        </div>
                    </div>
                    <div className="sumilla text-row">
                        <div className="text-esc text-dark">
                            <h5>Sumilla: </h5>
                        </div>
                        <div className="">
                            <h5>{registro.subject}</h5>
                        </div>
                    </div>
                </section>
            ) : (
                <Spinner margin="0rem"></Spinner>
            )}
            <section className="datos-documentos">
                {registro.documentSubmissionNumber ? (
                    <>
                        <div className="doc-principal" style={{ marginBottom: 10 }}>
                            <label className="text-primary">
                                <h4>{`Documento Principal:`}</h4>
                            </label>
                            <List
                                listItem="file-item"
                                override="override-btn-outline-primary"
                                list={principal}
                                onClick={() => console.log("Luego se implementará")}
                                isButton={true}
                            />
                        </div>
                        {anexos.length > 0 && (
                            <div className="doc-anexos">
                                <label className="text-primary">
                                    <h4>{`Documento(s) Anexo(s):`}</h4>
                                </label>
                                <List
                                    listItem="file-item"
                                    list={anexos}
                                    onClick={() => console.log("Luego se implementará")}
                                    isButton={true}
                                />
                            </div>
                        )}
                    </>
                ) : null}
            </section>
        </menu>
    );
}
export default NewTabEscrito;