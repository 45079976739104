import React from "react";

export const filterInputs = [
  {
    id: "doc",
    name: "documento",
  },
  {
    id: "num",
    name: "numero",
    type: "number",
    color: "dark",
  },
  {
    id: "nom",
    name: "nombre",
    type: "text",
    color: "dark",
  },
  {
    id: "exp",
    name: "expediente",
    type: "text",
    color: "dark",
    placeholder: "N° de Expediente",
  },
  {
    id: "email",
    type: "text",
    color: "dark",
    placeholder: "Correo electrónico",
  },
];
