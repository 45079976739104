import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { autoLogin } from "../../services/authentication.js";
import BandejaSolicitudes from "./BandejaSolicitudes.js";

function Authentication() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let loginIndex = location.search.toString().indexOf("auth=");
    let userKey = "";
    if (loginIndex !== -1) {
      userKey = location.search
        .toString()
        .substring(loginIndex + "auth=".length);
    } else {
      if (!localStorage.getItem("user")) {
        navigate("/");
      } else {
        navigate(`${process.env.REACT_APP_CONTEXT}`);
      }
    }
    if (userKey.length >= 0) {
      autoLogin(userKey).then((data) => {
        if (data.resultCode === 0) {
          localStorage.setItem("user", data.userId);
          navigate(`${process.env.REACT_APP_CONTEXT}`);
        } else {
          localStorage.removeItem("user");
          navigate("/");
        }
      });
    }
  }, []);

  return <div>{ }</div>;
}
export default Authentication;
